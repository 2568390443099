import React from "react";
import "./SpravaOdpovediDetail.scss";

class SpravaOdpovediDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogDetail: this.props.dialogDetail,
      show: false,
      selectedPlatform: "",
    };
  }

  parseResponse = () => {
    if (this.state.dialogDetail.dialogNodes) {
      let i = 0;
      let data = this.state.dialogDetail.dialogNodes;
      //console.log(data);
      //console.log(this.props.selectedPlatform);
      //console.log(this.props.selectedPlatform);
      const dialogDetail = data
        .filter((dataf) => {
          var selectedPlatformprop = "";
          if (
            !this.props.selectedPlatform ||
            this.props.selectedPlatform.includes(",")
          ) {
            selectedPlatformprop = this.props.dialogDetail.dialogNodes[0]
              .platforms[0];
          } else {
            selectedPlatformprop = this.props.selectedPlatform;
          }
          const checkString = (element) => element === selectedPlatformprop;
          if (dataf.platforms.some(checkString)) {
            return true;
          } else {
            return false;
          }
        })
        .map((data) => {
          i++
          let i2 = 0;
          if (data.dialog.content) {
            const contents = data.dialog.content.map((data) => {
              i2++;
              if (data.response_type === "IMAGE") {
                return (
                  <div
                    key={"i_" + i2}
                    className="dialogDetailImage row dialogDetailMessage"
                  >
                    <div className="col">
                      <img alt="" src={data.source} />
                    </div>
                  </div>
                );
              } else if (data.response_type === "TEXT" && data.text) {
                return (
                  <div
                    key={"t1_" + i2}
                    className="dialogDetailText row dialogDetailMessage"
                  >
                    <div className="col">
                      <p dangerouslySetInnerHTML={{ __html: data.text }}></p>
                    </div>
                    <div className="chatAvatar">
                      <img
                        alt=""
                        className=""
                        src="https://addai.life/wp-content/uploads/2020/05/Webchat-icon.png"
                      />
                    </div>
                  </div>
                );
              } else if (data.response_type === "OPTION") {
                let i3 = 0;
                const detailOption = data.options.map((data) => {
                  i3++;
                  return (
                    <div key={"op_" + i3} className="option dialogDetailOption">
                      <p>{data.label}</p>
                    </div>
                  );
                });
                if (data.title) {
                  return (
                    <div key={"o_" + i2} className="">
                      <div className="row">
                        <div
                          key={"t2_" + i2}
                          className="dialogDetailText row dialogDetailMessage"
                        >
                          <div className="col">
                            <p>{data.title}</p>
                          </div>
                          <div className="chatAvatar">
                            <img
                              alt=""
                              className=""
                              src="https://addai.life/wp-content/uploads/2020/05/Webchat-icon.png"
                            />
                          </div>
                        </div>
                        <div className="dialogDetailOptions">
                          {detailOption}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div key={"o_" + i2} className="">
                      <div className="row">
                        <div className="dialogDetailOptions">
                          {detailOption}
                        </div>
                      </div>
                    </div>
                  );
                }
              }
              return "";
            });
            return contents;
          } else return (
            <div key={"no_data_SO_" + i}>
              No content
            </div>
          )
        });
      return dialogDetail;
    } else {
      return "No Entry";
    }
  };

  render() {
    return (
      <div className="SpravaOdpovediDetail container">
        <div className="chatContainer">
          <div className="chatBody">{this.parseResponse()}</div>
        </div>
      </div>
    );
  }
}

export default SpravaOdpovediDetail;
