
class Project {
    constructor(id, name, dataVersionDevelopment, dataVersionProduction) {
        this.id = id;
        this.name = name;
        this.dataVersionDevelopment = dataVersionDevelopment;
        this.dataVersionProduction = dataVersionProduction;
    }
}

export default Project;