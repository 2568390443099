import React from "react";
import TalkingCamel from "../../../../api/TalkingCamel";
import "../SpravaOdpovediNewDialog.scss";
import ReactDOM from "react-dom";
import Popup from "../../../../components/popup/Popup";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";

class SpravaOdpovediNewDialogCreate extends React.Component {
  constructor(props) {
    super(props);
    const initQuestion = "";
    const initAnswer = "";
    const contentBlockQuestion = htmlToDraft(initQuestion);
    const contentBlockAnswer = htmlToDraft(initAnswer);
    this.state = {
      title: "",
      question: "",
      answer: "",
    };

    if (contentBlockQuestion) {
      const contentBlockQuestionState = ContentState.createFromBlockArray(
        contentBlockQuestion.contentBlocks
      );
      const editorQuestionState = EditorState.createWithContent(
        contentBlockQuestionState
      );
      this.state = {
        editorQuestionState,
      };
    }

    if (contentBlockAnswer) {
      const contentBlockAnswerState = ContentState.createFromBlockArray(
        contentBlockAnswer.contentBlocks
      );
      const editorAnswerState = EditorState.createWithContent(
        contentBlockAnswerState
      );
      this.state = {
        editorAnswerState,
      };
    }
  }

  componentDidMount = () => {};

  componentDidUpdate = () => {};

  submitForm = (e) => {
    e.preventDefault();
    let question = JSON.stringify(
      convertToRaw(this.state.editorQuestionState.getCurrentContent())
    );
    let answer = JSON.stringify(
      convertToRaw(this.state.editorAnswerState.getCurrentContent())
    );
    TalkingCamel.post(
      "/api/dialog-texts/v2/" + this.props.project + "/dialog-new",
      {
        title: this.state.title,
        question: question,
        answer: answer,
      }
    )
      .then((response) => {
        this.props.switchDialogNewCreateState();
        ReactDOM.render(
          <Popup type="correct" text={"Dialog created succesfully"} />,
          document.getElementById("popup-window")
        );
        this.setState({
          dialogShort: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        let errorMessage = "Unknown error occurred";
        console.log(error);
        if(error)
          if(error.response)
            if(error.response.data)
              if(error.response.data[0])
                errorMessage = error.response.data[0].error;
        ReactDOM.render(
            <Popup type="error" text={errorMessage} />,
            document.getElementById("popup-window"));
      })
  }

  onQuestionEditorStateChange = (editorQuestionState) => {
    this.setState({
      editorQuestionState,
    });
  };

  onAnswerEditorStateChange = (editorAnswerState) => {
    this.setState({
      editorAnswerState,
    });
  };

  renderTitle = () => {
    return (
      <div className="row">
        <div className="col">
          <h4 className="title-main">New Dialog</h4>
        </div>
      </div>
    );
  };

  renderQuestionBlock = () => {
    const { editorQuestionState } = this.state;
    return (
      <div className="createQuestions">
        <h4>Questions</h4>
        <Editor
          editorState={editorQuestionState}
          wrapperClassName="wrapper"
          editorClassName="editor"
          onEditorStateChange={this.onQuestionEditorStateChange}
        />
        <p className="note">*Please enter 5-10 questions on the topic of this dialogue</p>
      </div>
    );
  };

  renderAnswerBlock = () => {
    const { editorAnswerState } = this.state;
    return (
      <div className="createAnswers">
        <h4>Answers</h4>
        <Editor
          editorState={editorAnswerState}
          wrapperClassName="wrapper"
          editorClassName="editor"
          onEditorStateChange={this.onAnswerEditorStateChange}
        />
        <p className="note">*Please enter assistant response to your topic, including: Text, buttons, Hyperlinks etc</p>
      </div>
    );
  };

  render() {
    return (
      <div className="SpravaOdpovediNewDialogCreate-wrap">
        <div className="SpravaOdpovediNewDialogCreate-renderPrehled conainer">
          {/*this.renderTitle()*/}
          <h4>Title</h4>
          <form className="container" onSubmit={this.submitForm}>
            <div className=" row">
              <div className=" col">
                <TextField
                  required
                  //label="Title"
                  multiline
                  fullWidth
                  onChange={(e) => this.setState({ title: e.target.value })}
                />
                <p className="note">Insert your dialog name</p>
              </div>
            </div>
            <div className=" row">
              <div className=" col">
                {this.renderQuestionBlock()}
                <div className=" row">
                  <div className=" col">{this.renderAnswerBlock()}</div>
                </div>
              </div>
            </div>
            <div className=" row">
              <div className=" col submit-new-dialog">
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={(e) => this.setState({ dialogNewShow: false })}
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default SpravaOdpovediNewDialogCreate;
