import "./GetProjectVersion.scss";

const GetProjectVersion = (project, projectPrev) => {
  if (window.localStorage.getItem("data")) {
    const projectInfo = JSON.parse(window.localStorage.getItem("data")).projects;
    return projectInfo.find( it=> it.id === project );
  }
};

export default GetProjectVersion;
