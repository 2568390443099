import React from "react";
import "./SpravaOdpovediPrehledRow.scss";
import SpravaOdpovediDetail from "../SpravaOdpovediDetail/SpravaOdpovediDetail";
import SpravaOdpovediKomentar from "../spravaOdpovediKomentar/SpravaOdpovediKomentar";
import TalkingCamel from "../../../api/TalkingCamel";
import ReactDOM from "react-dom";
import Popup from "../../popup/Popup";
import Loading from "../../loading/Loading";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fade from "@material-ui/core/Fade";

class SpravaOdpovediPrehledRow extends React.Component {
  state = {
    showDetail: false,
    status: this.props.dialogShortstatus,
    dialogDetail: [],
    selectedPlatform: "",
    selectedDialogNode: [],
    loading: true,
    updateCommentsBool: false,
    updatePlatforms: false,
  };

  componentDidMount = () => {
    this.setState({ prevProject: this.props.project,});
  };

  componentDidUpdate = () => {
    if (this.state.prevProject !== this.props.project) {
      this.setState({ prevProject: this.props.project });
    }

    if (this.state.updateCommentsBool || this.state.updatePlatforms) {
      this.parseId();
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      status: nextProps.dialogShortstatus,
    };
  }

  updateComments = () => {
    this.getDetail();
  };

  toggleDetail = () => {
    this.setState((prevState) => ({
      showDetail: prevState.showDetail === true ? false : true,
    }));
  };

  getDetail = () => {
    TalkingCamel.get(
      "/api/dialog-texts/v2/" +
        this.props.project +
        "/" +
        this.props.dialogShortId
    )
      .then((response) => {
        //console.log(response);
        let selectedPlatform = this.state.selectedPlatform
          ? this.state.selectedPlatform
          : response.data.dialogNodes[0].platforms.join(", ");
        this.setState({
          dialogDetail: response.data,
          showDetail: true,
          selectedPlatform: selectedPlatform,
          updateCommentsBool: true,
        });
      })
      .catch((error) => {
        let errorMessage = "";
        if (error) {
          if (error.response) {
            if (error.response.data) {
              if (error.response.data[0]) {
                if (error.response.data[0].error) {
                  errorMessage = error.response.data[0].error;
                } else {
                  errorMessage = "Unknown Error SpravaOdpovedi";
                }
              } else {
                errorMessage = "Unknown Error SpravaOdpovedi";
              }
            } else {
              errorMessage = "Unknown Error SpravaOdpovedi";
            }
          } else {
            errorMessage = "Unknown Error SpravaOdpovedi";
          }
        } else {
          errorMessage = "Unknown Error SpravaOdpovedi";
        }
        ReactDOM.render(
          <Popup type="error" text={errorMessage} />,
          document.getElementById("popup-window")
        );
      });
  };

  parsePlarform = () => {
    let data = this.props.platforms;
    const platforms = data
      .sort(function (a, b) {
        if (a.data < b.data) {
          return -1;
        }
        if (a.data > b.data) {
          return 1;
        }
        return 0;
      })
      .map((platform) => {
        return platform + " ";
      });
    return platforms;
  };

  renderPlatfromsControll = () => {
    let i = 0;
    let data = this.state.dialogDetail.dialogNodes;
    const dialogNodes = data
    .sort(function (a, b) {
      if (a.platforms.join(", ") < b.platforms.join(", ")) {
        return -1;
      }
      if (a.platforms.join(", ") > b.platforms.join(", ")) {
        return 1;
      }
      return 0;
    })
    .map((dialogNode) => {
      i++;
      if (this.state.dialogDetail.dialogNodes.length === 1) {
        this.parseId();
        return (
          <div
            key={"platformControl" + i}
            className={"platform-control-col active-platform"}
          >
            {dialogNode["change-request-comments"]
              ? this.renderCommentNotification()
              : ""}
            <button>{dialogNode.platforms.join(", ")}</button>
          </div>
        );
      } else {
        return (
          <div
            key={"platformControl" + i}
            className={
              this.state.selectedPlatform === dialogNode.platforms.join(", ")
                ? "active-platform platform-control-col"
                : "platform-control-col"
            }
          >
            {dialogNode["change-request-comments"]
              ? this.renderCommentNotification()
              : ""}
            <button
              onClick={(e) =>
                this.setState(
                  { selectedPlatform: dialogNode.platforms.join(", "), updatePlatforms: true, },
                )
              }
            >
              {dialogNode.platforms.join(", ")}
            </button>
          </div>
        );
      }
    });
    return dialogNodes;
  };

  renderCommentNotification = () => {
    return <div className="notification-icon">!</div>;
  };

  parseId = () => {
    if (
      this.state.dialogDetail.dialogNodes.length === 1 &&
      (this.state.updateCommentsBool || this.state.updatePlatforms)
    ) 
    {
      let data = this.state.dialogDetail.dialogNodes[0];
      this.setState({
        dialogNodeId: data.dialogNodeId,
        comments: data["change-request-comments"],
        updateCommentsBool: false,
        updatePlatforms: false,
        resolvedComments: data["resovled-change-requests"],
      });
    } else if (this.state.updateCommentsBool || this.state.updatePlatforms) {
      let data = this.state.dialogDetail.dialogNodes;
      data
        .filter((dataf) => {
          var selectedPlatformprop = "";
          if (!this.state.selectedPlatform) {
            selectedPlatformprop = this.state.dialogDetail.dialogNodes[0]
              .platforms[0];
          } else {
            selectedPlatformprop = this.state.selectedPlatform;
          }
          const checkString = (element) => element === selectedPlatformprop;
          if (dataf.platforms.some(checkString)) {
            return true;
          } else {
            return false;
          }
        })
        .forEach((data) => {
          this.setState({
            dialogNodeId: data.dialogNodeId,
            comments: data["change-request-comments"],
            resolvedComments: data["resovled-change-requests"],
            updateCommentsBool: false,
            updatePlatforms: false,
          });
        });
    }
  };

  renderDialog = () => {
    return (
      <Dialog
        open={this.state.showDetail}
        transitioncomponent={Fade}
        keepMounted
        onClose={this.toggleDetail}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg">
        <DialogTitle id="alert-dialog-slide-title">
          <div className={"so-title"}>
            <h2 className="bold">
              {this.props.dialogShortId + " - " + this.props.dialogShortTitle}
            </h2>
            <div className={"platform-control-row"}>
              <div className={"platform-control-row-block-1"}>
                {this.renderPlatfromsControll()}
              </div>
              <div className={"platform-control-row-block-2"}>
                <h4>
                  {" "}
                  Status:{" "}
                  <span
                    className={
                      this.state.dialogDetail.status === "CHANGE_REQUEST"
                        ? "textYellow"
                        : "textGreen"
                    }
                  >
                    {this.state.dialogDetail.status}
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="container">
            <div className="row row-detail">
              <div className="SpravaOdpovediDetail-wrap col-xl-4 col-lg-5 col-md-12 col-sm-12 col-xs-12">
                <SpravaOdpovediDetail
                  project={this.props.project}
                  dialogDetail={this.state.dialogDetail}
                  selectedPlatform={this.state.selectedPlatform}
                />
              </div>
              <div className="SpravaOdpovediKomentar-wrap col-xl-8 col-lg-7 col-md-12 col-sm-12 col-xs-12">
                <SpravaOdpovediKomentar
                  updateStatus={this.props.updateStatus}
                  userRole={this.props.userRole}
                  dialogDetail={this.state.dialogDetail}
                  dialogShortId={this.props.dialogShortId}
                  project={this.props.project}
                  username={this.props.username}
                  statusValue={this.state.status}
                  updateDetail={this.getDetail}
                  comments={this.state.comments}
                  dialogNodeId={this.state.dialogNodeId}
                  resolvedComments={this.state.resolvedComments}
                  updateComments={this.updateComments}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.toggleDetail} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    let status = "";
    this.updateStatus
      ? (status = this.state.status)
      : (status = this.state.status);
    if (status === "CHANGE_REQUEST") {
      status = "Change request";
    }
    if (status === "CHANGE_REQUEST_PROCESSED") {
      status = "Change request processed";
    }
    if (!this.state.loading) {
      return (
        <div className="SpravaOdpovediPrehledRow container">
          <Loading />
        </div>
      );
    } else {
      return (
        <div className="SpravaOdpovediPrehledRow container">
          <div
            className={
              "row_prehled row_prehled_" + this.props.rowNumber + " row"
            }
          >
            <div className={"col-1"}>{this.props.dialogShortId}</div>
            <div className={"col-5 smallerFont so-title"}>
              {this.props.dialogShortTitle}
            </div>
            <div className={"col-2"}>{this.parsePlarform()}</div>
            <div className={"col-2"}>{status}</div>
            <div className="col-2">
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                onClick={this.getDetail}
              >
                {this.state.showDetail ? "Hide detail" : "Show Detail"}
              </Button>
            </div>
          </div>
          {this.state.showDetail ? this.renderDialog() : ""}
        </div>
      );
    }
  }
}

export default SpravaOdpovediPrehledRow;
