import React from "react";
import TalkingCamel from "../../../api/TalkingCamel";
import ReactDOM from "react-dom";
import Popup from "../../../components/popup/Popup";

class UserManagementEmailNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectValue: "",
      projectValueName: "",
      showEmails: false,
      getEmails: [],
      addEmail: "",
    };
  }

  componentDidMount = () => {};

  getEmail = () => {
    if (this.state.projectValue !== "select") {
      TalkingCamel.get("/api/v1/subscriptions/" + this.state.projectValue)
        .then((response) => {
          console.log(response);
          this.setState({ getEmails: response.data, showEmails: true });
        })
        .catch((error) => {
          let errorMessage = "";
          !error.response.data[0].error
            ? (errorMessage = "Unknown Error UserManagementEmailNotifications")
            : (errorMessage = error.response.data[0].error);
          ReactDOM.render(
            <Popup type="error" text={errorMessage} />,
            document.getElementById("popup-window")
          );
        });
    }
  };

  updateEmail = (id) => {
    /*if (this.state.projectValue !== "select") {
      TalkingCamel.put(
        "/api/v1/subscriptions/" + this.state.projectValue + "/updating/" + id,
        {
          value: this.state.addEmail,
        },
        {
          headers: {
            Authorization: "Bearer " + this.props.accessToken,
          },
        }
      )
        .then((response) => {
          console.log(response);
          this.setState({ getEmails: response.data, showEmails: true }, () => {
            this.getEmail();
          });
        })
        .catch((error) => {
          let errorMessage = "";
          !error.response.data[0].error
            ? (errorMessage = "Unknown Error UserManagementEmailNotifications")
            : (errorMessage = error.response.data[0].error);
          ReactDOM.render(
            <Popup type="error" text={errorMessage} />,
            document.getElementById("popup-window")
          );
        });
    } else {
      ReactDOM.render(
        <Popup type="error" text="Select a project to proceed" />,
        document.getElementById("popup-window")
      );
    }*/
  };

  deleteEmail = (id) => {
    if (this.state.projectValue !== "select") {
      TalkingCamel.delete(
        "/api/v1/subscriptions/" +
          this.state.projectValue +
          "/cancelling/" +
          id
      )
        .then((response) => {
          console.log(response);
          this.setState({ getEmails: response.data, showEmails: true }, () => {
            this.getEmail();
          });
        })
        .catch((error) => {
          let errorMessage = "";
          !error.response.data[0].error
            ? (errorMessage = "Unknown Error UserManagementEmailNotifications")
            : (errorMessage = error.response.data[0].error);
          ReactDOM.render(
            <Popup type="error" text={errorMessage} />,
            document.getElementById("popup-window")
          );
        });
    } else {
      ReactDOM.render(
        <Popup type="error" text="Select a project to proceed" />,
        document.getElementById("popup-window")
      );
    }
  };

  postAddEmail = (e) => {
    e.preventDefault();
    if (this.state.projectValue !== "select") {
      TalkingCamel.post(
        "/api/v1/subscriptions/" + this.state.projectValue + "/subscription ",
        {
          value: this.state.addEmail,
        }
      )
        .then((response) => {
          console.log(response);
          this.setState({ getEmails: response.data, showEmails: true }, () => {
            this.getEmail();
          });
        })
        .catch((error) => {
          let errorMessage = "";
          !error.response.data[0].error
            ? (errorMessage = "Unknown Error UserManagementEmailNotifications")
            : (errorMessage = error.response.data[0].error);
          ReactDOM.render(
            <Popup type="error" text={errorMessage} />,
            document.getElementById("popup-window")
          );
        });
    } else {
      ReactDOM.render(
        <Popup type="error" text="Select a project to proceed" />,
        document.getElementById("popup-window")
      );
    }
  };

  renderProjects = () => {
    if (window.localStorage.getItem("data")) {
      let projects = "";
      window.localStorage.getItem("data")
        ? (projects = JSON.parse(window.localStorage.getItem("data")).projects)
        : (projects = "");
      let i = 0;
      const projectsList = projects.map((project) => {
        i++;
        return (
          <option
            key={"project_option_" + i}
            value={project.id}
            valuename={project.name}
          >
            {project.name}
          </option>
        );
      });
      return (
        <select
          onChange={(e) =>
            this.setState(
              {
                projectValue: e.target.value,
                projectValueName: e.target.valuename,
              },
              () => {
                this.getEmail();
              }
            )
          }
          className="form-control"
        >
          <option value={"select"}>- Select project -</option>
          {projectsList}
        </select>
      );
    } else {
      return "No projects to select";
    }
  };

  renderEmailContainer = () => {
    if (this.state.getEmails.length !== 0) {
      return (
        <div className="">
          <div className=""> {this.renderEmails()} </div>
        </div>
      );
    } else {
      return (
        <div className="">
          <div className=""> No emails set for this project</div>
        </div>
      );
    }
  };

  renderEmails = () => {
    let i = 0;
    let emails = this.state.getEmails;
    const emailsConst = emails.map((email) => {
      i++;
      return (
        <div className="row" key={"re-" + i}>
          <div className="col">{email.value}</div>
          <button
            type="submit"
            onClick={(e) => this.updateEmail(email.id)}
            className={"btn btn-primary col-2"}
          >
            {" "}
            Update Email
          </button>
          <button
            type="submit"
            onClick={(e) => this.deleteEmail(email.id)}
            className={"btn btn-primary col-2"}
          >
            {" "}
            Remove Email
          </button>
        </div>
      );
    });
    return emailsConst;
  };

  renderAddEmail = () => {
    return (
      <form className="row" onSubmit={this.postAddEmail}>
        <input
          type="text"
          placeholder="Username"
          className="form-control col-6"
          value={this.state.term}
          onChange={(e) => this.setState({ addEmail: e.target.value })}
        />
        <button type="submit" className={"btn btn-primary col-2"}>
          {" "}
          Add Email
        </button>
      </form>
    );
  };

  validateEmailString = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  validateEmail = () => {
    let email = this.state.newEmail;
    if (this.validateEmailString(email)) {
      ReactDOM.render(
        <Popup type="error" text="Valid email syntax" />,
        document.getElementById("popup-window")
      );
    } else {
      ReactDOM.render(
        <Popup type="error" text="Invalid email syntax" />,
        document.getElementById("popup-window")
      );
    }
    return false;
  };

  render() {
    return (
      <div className="UserManagementEmailNotifications container">
        <div className="row">
          <div className="col">
            <h5>UserManagementEmailNotifications</h5>
          </div>
        </div>
        <div className="row">
          <div className="col">{this.renderProjects()}</div>
        </div>
        <div className="row">
          <div className="col">
            {this.state.showEmails ? this.renderEmailContainer() : ""}
          </div>
        </div>
        {this.state.showEmails ? this.renderAddEmail() : ""}
      </div>
    );
  }
}

export default UserManagementEmailNotifications;
