
class User {
    constructor(username, firstname, lastname, email, roles) {
        this.username = username;
        this.firstname = firstname;
        this.lastname = lastname;
        this.email = email;
        this.roles = roles;
    }
}

export default User;