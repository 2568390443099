import React from "react";

export default function CrossIcon() {
  return (
    <svg
    width="2.8em"
    height="2.8em"
    viewBox="0 0 16 16"
    className="bi bi-exclamation"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
  </svg>
  );
}
