import React from "react";
import "../SpravaOdpovediNewDialog.scss";
import SpravaOdpovediNewDialogDetail from "../spravaOdpovediNewDialogDetail/SpravaOdpovediNewDialogDetail";
import CrossIcon from "../../../smallComponents/CrossIcon";
import CheckIcon from "../../../smallComponents/CheckIcon";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import Fade from "@material-ui/core/Fade";
import draftToHtml from "draftjs-to-html";

class SpravaOdpovediNewDialogList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetail: false,
    };
  }

  showDetail = () => {
    this.setState({
      showDetail: this.state.showDetail ? false : true,
    });
  };

  isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  createMarkup = (text) => {
    return { __html: draftToHtml(JSON.parse(text)) };
  };

  convertContentToHTML = (text) => {
    if (this.isJson(text)) {
      return (
        <div
          className="commentText dialogNew"
          dangerouslySetInnerHTML={this.createMarkup(text)}
        ></div>
      );
    } else {
      return <div className="commentText dialogNew">{text}</div>;
    }
  };

  render() {
    return (
      <div className="container SpravaOdpovediNewDialogListRow">
        <div className="row">
          <div className="col">{this.props.dialogNewShortTitle}</div>
          <div className="col">
            {new Intl.DateTimeFormat( "cs-CZ",{
              year: "numeric",
              month: "numeric",
              day: "2-digit",
            }).format(Date.parse(this.props.dialogNewShortCreatedAt))}
          </div>
          <div className="col">
            {this.props.accepted ? <CheckIcon /> : <CrossIcon />}
          </div>
          <div className="col">
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              onClick={(e) =>
                this.setState({
                  showDetail: this.state.showDetail ? false : true,
                })
              }
            >
              Edit
            </Button>
          </div>
        </div>
        <div className="row">
          {!this.state.showDetail ? (
            ""
          ) : (
            <Dialog
              open={this.state.showDetail}
              transitioncomponent={Fade}
              keepMounted
              onClose={this.showDetail}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              maxWidth="lg"
            >
              <DialogActions>
                <Button onClick={this.showDetail} color="primary">
                  X
                </Button>
              </DialogActions>
              <DialogContent>
                <SpravaOdpovediNewDialogDetail
                  project={this.props.project}
                  userRole={this.props.userRole}
                  dialogNewShortId={this.props.dialogNewShortId}
                  dialogNewShortDialogId={this.props.dialogNewShortDialogId}
                  dialogNewShortTitle={this.props.dialogNewShortTitle}
                  dialogNewShortQuestion={this.props.dialogNewShortQuestion}
                  dialogNewShortAnswer={this.props.dialogNewShortAnswer}
                  dialogNewShortCreatedAt={this.props.dialogNewShortCreatedAt}
                  getSODialogNewPreview={this.props.getSODialogNewPreview}
                  comments={this.props.comments}
                  username={this.props.username}
                  showDetail={this.showDetail}
                />
              </DialogContent>
            </Dialog>
          )}
        </div>
      </div>
    );
  }
}

export default SpravaOdpovediNewDialogList;
