import React from "react";
import "./Popup.scss";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textError: this.props.text,
      parameter: this.props.parameter,
      textErrorPrev: "",
      showPopup: true,
    };
  }

  componentDidMount = () => {
    this.switchErrorMessages();
  };

  componentWillUpdate = () => {
    if (!this.state.showPopup) {
      this.setState({ textError: this.props.text });
    }
    this.showToggle();
  };

  componentDidUpdate = () => {
    if (this.state.textError !== this.state.textErrorPrev) {
      this.switchErrorMessages();
    }
  };

  switchErrorMessages = () => {
    this.setState({ textErrorPrev: this.state.textError });
    switch (this.props.text) {
      case "WRONG_USERNAME_PASSWORD":
        this.setState({ textError: "Your username or password is invalid" });
        break;
      case "VersionsOrFileMissingImport":
        this.setState({ textError: "Missing version parameters" });
        break;
      case "userCreated":
        this.setState({ textError: "User created successfully" });
        break;
      case "Unknown Error SpravaOdpovediKomentar getKomentar":
        this.setState({
          textError: "Something went wrong, we are sorry for inconvenience",
        });
        break;
      case "Unknown Error SpravaOdpovediKomentar get":
        this.setState({
          textError: "Something went wrong, we are sorry for inconvenience",
        });
        break;
      case "WRONG_COMMENT_ID":
        this.setState({ textError: "Comment error" });
        break;
      case "WRONG_DIALOG_ID":
        this.setState({ textError: "Dialogue error" });
        break;
      case "NOT_PERMITED":
        this.setState({ textError: "You don't have permissions" });
        break;
      case "Unknown Error SpravaOdpovediKomentar onFormSubmitKomentar":
        this.setState({
          textError: "Something went wrong, we are sorry for inconvenience",
        });
        break;
      case "Unknown Error SpravaOdpovediKomentar deleteKomentar":
        this.setState({
          textError: "Something went wrong, we are sorry for inconvenience",
        });
        break;
      case "Network Error":
        this.setState({
          textError: "Network Error, something went wrong, we are sorry for inconvenience",
        });
        break;
      case "Unknown Error SpravaOdpovediDetail":
        this.setState({
          textError: "Something went wrong, we are sorry for inconvenience",
        });
        break;
      case "Unknown Error SpravaOdpovedi":
        this.setState({
          textError: "Something went wrong, we are sorry for inconvenience",
        });
        break;
      case "Unknown Error Login":
        this.setState({ textError: "Login failed, please try again" });
        break;
      case "Unknown Error UserManagementCreateUser createUser":
        this.setState({
          textError: "Something went wrong, we are sorry for inconvenience",
        });
        break;
      case "Unknown Error UserManagementCreateUser addRole":
        this.setState({
          textError: "Something went wrong, we are sorry for inconvenience",
        });
        break;
      case "Unknown Error UserManagementList":
        this.setState({
          textError: "Something went wrong, we are sorry for inconvenience",
        });
        break;
        case "Failed to refresh token":
          this.setState({
            textError: "Failed to refresh token, please try to sign in again",
          });
          break;

      case "Unknown Error SpravaOdpovediKomentar changeRequestSubmit":
        this.setState({
          textError: "Something went wrong, we are sorry for inconvenience",
        });
        break;
      case "Unknown Error SpravaOdpovediKomentar changeRequestSubmitAccept":
        this.setState({
          textError: "Something went wrong, we are sorry for inconvenience",
        });
        break;
      case "Unknown Error UserManagementEdit onSubmitDeleteUser":
        this.setState({
          textError: "Something went wrong, we are sorry for inconvenience",
        });
        break;
      case "Unknown Error UserManagementEdit onSubmitEditUser":
        this.setState({
          textError: "Something went wrong, we are sorry for inconvenience",
        });
        break;
      case "Change request was set to ACCEPTED":
        this.setState({ textError: "Change request ACCEPTED" });
        break;
      case "Change request was set to REJECTED":
        this.setState({ textError: "Change request REJECTED" });
        break;
      case "Change request was set to CANCELED":
        this.setState({ textError: "Change request CANCELED" });
        break;
      case "Error no role assigned":
        this.setState({ textError: "No role assigned, please assign roles" });
        break;
      case "fillCreateUserForm":
        this.setState({
          textError: "Please fill username, password and email",
        });
        break;
      case "Please insert dialog ID":
        this.setState({
          textError: "Please insert dialog ID",
        });
        break;
      case "dataImportComplete":
        this.setState({ textError: "Data import submitted" });
        break;
      case "dataImportFailed":
        this.setState({ textError: "Data import failed" });
        break;
      case "passwordChangeSuccess":
        this.setState({ textError: "Password Changed Successfully" });
        break;
      case "Submit went correctly":
        this.setState({ textError: "Submit went correctly" });
        break;
      case "Dialog node Accepted! good job :).":
        this.setState({ textError: "Dialog node Accepted! good job :)." });
        break;
      case "Dialog created succesfully":
        this.setState({ textError: "Dialog created succesfully" });
        break;
      case "DUPLICATE_DIALOG_ID":
        this.setState({ textError: "Duplicate Dialogue ID " + this.state.parameter + " .Check if dialogue id and platform is correct. Dialogue Title must comply with defined rules." });
        break;
      case "DUPLICATE_NEW_DIALOG_ID":
        this.setState({ textError: "Duplicate New Dialogue ID for selected project. Change new dialogue id." });
        break;
      case !this.props.text:
        this.setState({ textError: "Unknown Error missing props text" });
        break;
      default:
        this.setState({
          textError: this.props.text + " default - missing case",
        });
        break;
    }
  };

  showToggle = () => {
    if (!this.state.showPopup) {
      this.setState({ showPopup: true });
    }
  };

  closePopuop = () => {
    this.setState({ showPopup: false });
  };

  render() {
    if (this.state.showPopup) {
      if (this.props.type === "correct") {
        return (
          <ClickAwayListener onClickAway={this.closePopuop}>
            <div className={"Popup-wrap-container " + this.props.type}>
              <div className="Popup-wrap">
                <div className="Popup-wrap-message">{this.state.textError}</div>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.closePopuop}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </ClickAwayListener>
        );
      } else if (this.props.type === "error") {
        return (
          <ClickAwayListener onClickAway={this.closePopuop}>
            <div className={"Popup-wrap-container " + this.props.type}>
              <div className="Popup-wrap">
                <div className="Popup-wrap-message">{this.state.textError}</div>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.closePopuop}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </ClickAwayListener>
        );
      } else return "";
    } else return "";
  }
}

export default Popup;
