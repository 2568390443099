import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./App.scss";
import "./Mobile.scss";
import SpravaOdpovedi from "./components/spravaOdpovedi/SpravaOdpovedi";
import DataImport from "./components/dataImport/DataImport";
import ProjectSelect from "./components/projectSelect/ProjectSelect";
import UserManagement from "./components/userManagement/UserManagement";
import { withStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import User from "./models/User";
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Project from "./models/Project";
import TalkingCamel from "./api/TalkingCamel";
import ReactDOM from "react-dom";
import Popup from "./components/popup/Popup";
import LoginResponse from "./models/LoginResponse";
import keycloak from "./api/Keycloak";

const refreshAccessTokenInterval = 1770; //s

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
});

/*
<div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>xs=12</Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.paper}>xs=6</Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.paper}>xs=6</Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper className={classes.paper}>xs=3</Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper className={classes.paper}>xs=3</Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper className={classes.paper}>xs=3</Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper className={classes.paper}>xs=3</Paper>
              </Grid>
            </Grid>
          </div>
*/

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    return {
      loggedIn: false,
      username: "",
      accessToken: "",
      refreshToken: "",
      project: "",
      projects: [],
      projectName: "",
      showContent: "SpravaOdpovedi",
      userRole: [],
      heightMenu: 0,
      showMobile: false,
      screenWidth: 0,
      showMenu: false,
    };
  }

  componentDidMount = () => {
    this.setState({ screenWidth: window.innerWidth });
    if (window.localStorage.getItem("data")) {
      if (this.state.userRole.length === 0) {
        this.setRolesArr();
      }

      if (!this.state.project) {
        let projectsLocal = JSON.parse(window.localStorage.getItem("data"))
          .projects[0].id;
        this.setState({ project: projectsLocal });
      }

      if (!this.state.projectName) {
        let projectsLocal = JSON.parse(window.localStorage.getItem("data"))
          .projects[0].name;
        this.setState({ projectName: projectsLocal });
      }

      if (!this.state.username) {
        let usernameLocal = JSON.parse(window.localStorage.getItem("data")).user
          .username;
        this.setState({ username: usernameLocal });
      }
      if (!this.state.user) {
        let userLocal = JSON.parse(window.localStorage.getItem("data")).user;
        this.setState({ user: userLocal });
      }
    }
  };

  componentDidUpdate = () => {
    if (window.localStorage.getItem("data")) {
      if (this.state.userRole.length === 0) {
        this.setRolesArr();
      }

      if (!this.state.project) {
        let projectsLocal = JSON.parse(window.localStorage.getItem("data"))
          .projects[0].id;
        this.setState({ project: projectsLocal });
      }

      if (!this.state.projectName) {
        let projectsLocal = JSON.parse(window.localStorage.getItem("data"))
          .projects[0].name;
        this.setState({ projectName: projectsLocal });
      }

      if (!this.state.username) {
        let usernameLocal = JSON.parse(window.localStorage.getItem("data")).user
          .username;
        this.setState({ username: usernameLocal });
      }
      if (!this.state.user) {
        let userLocal = JSON.parse(window.localStorage.getItem("data")).user;
        this.setState({ user: userLocal });
      }
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  setRolesArr = () => {
    let userRoleLocal = JSON.parse(window.localStorage.getItem("data")).user.roles;
    this.setState({ userRole: userRoleLocal });
  };

  handleOnEvent = async (event, error) => {
    if (event === 'onAuthSuccess') {
      if (keycloak.authenticated) {
        const profile = await keycloak.loadUserProfile();
        const clientRoles = keycloak.realmAccess.roles.filter(role =>
          role.startsWith("ADMIN")
          || role.startsWith("CUSTOMER_EDITOR")
          || role.startsWith("CUSTOMER_READER")
          || role.startsWith("EDITOR")
        );
        if (clientRoles === undefined) {
          ReactDOM.render(
            <Popup type="error" text="Error no role assigned" />,
            document.getElementById("popup-window")
          );
        } else {
          let roles = clientRoles;
          let keycloakUser = new User(profile.username, profile.firstName, profile.lastName, profile.email, roles);
          this.getProjects(keycloakUser, keycloak.token);
          this.LoginState(profile.username, keycloak.token, keycloak.refreshToken, roles, []);
          window.localStorage.setItem("accessToken", keycloak.token);
          window.localStorage.setItem("refreshToken", keycloak.refreshToken);
          this.setState({ user: keycloakUser });
          this.interval = setInterval(() => this.setTokenRefreshInterval(), refreshAccessTokenInterval * 1000);
        }
      }
    }
  }

  setTokenRefreshInterval = () => {
    keycloak.updateToken(refreshAccessTokenInterval).success((refreshed) => {
      if (refreshed) {
        console.log('refreshed ' + new Date());
        window.localStorage.setItem("accessToken", keycloak.token);
        this.setState({ accessToken: keycloak.token });
      } else {
        ReactDOM.render(
          <Popup type="error" text="Failed to refresh token" />,
          document.getElementById("popup-window")
        );
      }
    }).error(() => {
      ReactDOM.render(
        <Popup type="error" text="Failed to refresh token" />,
        document.getElementById("popup-window")
      );
    });
  };

  LoginState = (username, accessToken, refreshToken, userRole, projects) => {
    this.setState({
      loggedIn: true,
      username: username,
      accessToken: accessToken,
      refreshToken: refreshToken,
      userRole: userRole,
      projects: projects,
    });
  };

  switchLoggedInState = () => {
    clearInterval(this.interval);
    this.setState(this.initialState);
    window.localStorage.removeItem("data");
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");
    keycloak.logout();
  };


  onProjectSelect = (selectedProject, valueName) => {
    this.setState({ project: selectedProject, projectName: valueName });
  };

  changeToMobileMenu = () => {
    document.getElementById("top-menu-right").classList.add("mobile");
    this.setState({ showMobile: true });
  };

  changeToStandartMenu = () => {
    document.getElementById("top-menu-right").classList.remove("mobile");
    this.setState({ showMobile: false });
  };
  recordButtonPosition = (event) => {
    this.setState({ anchorEl: event.currentTarget, showMenu: true });
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      showMenu: prevState.showMenu === true ? false : true,
    }));
  };

  handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      this.handleToggle();
    }
  };

  getProjects = (user, token) => {
    TalkingCamel.post('/api/v1/projects', user.roles,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        let projects = response.data.map(project => new Project(
          project.id,
          project.name,
          project.dataVersionDevelopment,
          project.dataVersionProduction
        ));
        if (projects.length > 0) {
          this.setState({ projects: projects });
          let loginResponse = new LoginResponse(user, null, projects);
          window.localStorage.setItem("data", JSON.stringify(loginResponse));
          this.setState({ project: projects[0].id });
        } else {
          ReactDOM.render(
            <Popup type="error" text="Error no role assigned" />,
            document.getElementById("popup-window")
          );
        }
      })
      .catch((error) => {
        ReactDOM.render(
          <Popup type="error" text="Network Error" />,
          document.getElementById("popup-window")
        );
      });
  };

  renderAvatar = () => {
    if (this.state.user) {
      return (
        <span
          className="main-title-user-avatar"
          onClick={this.recordButtonPosition}
        >
          <span className="main-title-user-avatar-first">
            {this.state.user.firstname
              ? this.state.user.firstname.slice(0, 1)
              : "A"}
          </span>
          <span className="main-title-user-avatar-last">
            {this.state.user.lastname
              ? this.state.user.lastname.slice(0, 1)
              : "D"}
          </span>
        </span>
      );
    } else {
      return (
        <span
          className="main-title-user-avatar"
          onClick={this.recordButtonPosition}
        >
          <span className="main-title-user-avatar-first"></span>
          <span className="main-title-user-avatar-last"></span>
        </span>
      );
    }
  };
  /*
<MenuItem
              onClick={(e) => {
                this.setState(
                  {
                    showContent: "Dashboard",
                  },
                  () => {
                    this.handleToggle();
                  }
                );
              }}
            >
              Dashboard
            </MenuItem>
*/
  renderMenu = () => {
    return (
      <div className="menu">
        <div>
          <ClickAwayListener
            onClickAway={() => {
              this.setState({ showMenu: false });
            }}
          >
            {this.renderAvatar()}
          </ClickAwayListener>
          <Menu
            id="menu-list-grow"
            onKeyDown={this.handleListKeyDown}
            transitioncomponent={Fade}
            open={this.state.showMenu}
            anchorEl={this.state.anchorEl}
          >
            <MenuItem
              onClick={(e) => {
                this.setState(
                  {
                    showContent: "SpravaOdpovedi",
                  },
                  () => {
                    this.handleToggle();
                  }
                );
              }}
            >
              Dialog management
            </MenuItem>
            {this.state.userRole.includes("ADMIN") ||
              this.state.userRole.includes("EDITOR:" + this.state.project) ? (
              <MenuItem
                onClick={(e) => {
                  this.setState(
                    {
                      showContent: "Import",
                    },
                    () => {
                      this.handleToggle();
                    }
                  );
                }}
              >
                Data Import
              </MenuItem>
            ) : (
              ""
            )}
            {this.state.userRole.includes("ADMIN") ? (
              <MenuItem
                onClick={(e) => {
                  this.setState(
                    {
                      showContent: "UserManagement",
                    },
                    () => {
                      this.handleToggle();
                    }
                  );
                }}
              >
                Notification Management
              </MenuItem>
            ) : (
              ""
            )}
            <MenuItem onClick={this.switchLoggedInState}>Logout</MenuItem>
          </Menu>
        </div>
      </div>
    );
  };

  render() {
    return (
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={
          {
            onLoad: 'login-required',
            checkLoginIframe: false
          }
        }
        onEvent={(event, error) => this.handleOnEvent(event, error)}>
        {this.state.loggedIn ?
          <div
            className={"App " + (this.state.loggedIn ? "loggedIn" : "loggeOut")}
          >
            <div className="App-header">
              <div className="main-title-logo">
                <img
                  alt="Main Logo"
                  id="logo-header"
                  src="/Logo-transparent.png"
                />
              </div>
              <div className="main-title">
                <h1>Assistant Administration</h1>
              </div>
              <div className="top-menu-right" id="top-menu-right">
                {this.state.userRole[1] ||
                  this.state.userRole.includes("ADMIN") ? (
                  <ProjectSelect projects={this.state.projects} onProjectSelect={this.onProjectSelect} />
                ) : (
                  ""
                )}
                {this.renderMenu()}
              </div>
            </div>
            <div className="container">
              <div className="App-body">
                {this.state.showContent === "Import" ? (
                  <DataImport
                    project={this.state.project}
                    userRole={this.state.userRole}
                    accessToken={this.state.accessToken}
                  />
                ) : (
                  ""
                )}
                {this.state.showContent === "SpravaOdpovedi" &&
                  this.state.project ? (
                  <SpravaOdpovedi
                    userRole={this.state.userRole}
                    project={this.state.project}
                    accessToken={this.state.accessToken}
                    username={this.state.username}
                    projectName={this.state.projectName}
                  />
                ) : (
                  ""
                )}
                {this.state.showContent === "UserManagement" ? (
                  <UserManagement
                    username={this.state.username}
                    accessToken={this.state.accessToken}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="App-footer">
              <div className="col">Made by AddAI team</div>
            </div>
          </div>
          : null
        }
      </ReactKeycloakProvider>
    )
  }
}

export default withStyles(useStyles)(App);
