import React from "react";
import "./SpravaOdpovediPrehled.scss";
import SpravaOdpovediPrehledRow from "../SpravaOdpovediPrehledRow/SpravaOdpovediPrehledRow";

class SpravaOdpovediPrehled extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      platformsForFilter: [],
      currentTotalDialogues: 0,
    };
  }
  renderPrehled = (props) => {
    let i = 0;
    let currentTotal = 0;
    let requestStatus = "";
    if (this.props.requestStatus === "CHANGE_REQUEST") {
      requestStatus = "CHANGE_REQUEST_ADDED";
    } else if (this.props.requestStatus === "ALL") {
      requestStatus = "";
    } else {
      requestStatus = this.props.requestStatus;
    }
    const dialogShort = []
      .concat(this.props.dialogShort)
      .map((dialogShort) => {
        i++;
        let dialogTerm = "";
        let searchedTerm = "";
        let status = "";
        let dialogShortId = "";
        let dialogPlatforms = [];
        if (dialogShort.status === "CHANGE_REQUEST") {
          status = "CHANGE_REQUEST_ADDED";
        } else {
          status = dialogShort.status;
        }
        if (dialogShort.title) {
          dialogTerm = dialogShort.title;
        }
        if (dialogShort.title) {
          dialogShortId = dialogShort.id;
        }
        if (this.props.searchedTerm) {
          searchedTerm = this.props.searchedTerm;
        }
        if (this.props.platformsListForFiltering) {
          dialogPlatforms = this.props.platformsListForFiltering;
        }
        if (
          (dialogTerm.toLowerCase().includes(searchedTerm.toLowerCase()) ||
            dialogShortId.toLowerCase().includes(searchedTerm.toLowerCase())) &&
          status.includes(requestStatus) &&
          (dialogPlatforms === undefined || dialogPlatforms.length === 0
            ? dialogShort.platforms
            : dialogShort.platforms.some(
                (r) => dialogPlatforms.indexOf(r) >= 0
              ))
        ) {
          currentTotal++;
          return (
            <SpravaOdpovediPrehledRow
              userRole={this.props.userRole}
              key={"SOD_" + i}
              dialogShortId={dialogShort.id}
              dialogShortTitle={dialogTerm}
              dialogShortstatus={dialogShort.status}
              rowNumber={i}
              project={this.props.project}
              username={this.props.username}
              updateStatus={this.props.updateStatus}
              platforms={dialogShort.platforms}
            />
          );
        }
        return "";
      });
    return (
      <div className="SpravaOdpovediPrehled-wrap">
        <div className="container SpravaOdpovediPrehledRow">
          <div className={"row_prehled_" + this.props.rowNumber + " row"}>
            <div className={"col-1"}>ID</div>
            <div className="col-5">Title</div>
            <div className={"col-2"}>Platforms</div>
            <div className={"col-2"}>Status</div>
            <div className={"col-2"}>Current Dialogues: {currentTotal}</div>
          </div>
        </div>
        {dialogShort}
      </div>
    );
  };

  render() {
    return this.renderPrehled();
  }
}

export default SpravaOdpovediPrehled;
